import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import BannerList from '../../core/sysext/Website/containers/Banner/components/List';

const BannersPage: FC<PageProps> = props => {
  const { location } = props;

  return (
    <Backoffice location={location}>
      <BannerList {...props} />
    </Backoffice>
  );
};

export default BannersPage;
